.menuLogout {
  .MuiPopover-paper {
    top: 57px !important;
    margin-left: -25px !important;
    width: 260px !important;
  }
}

.MuiMenuItem-gutters {
  background-color: #f7f7f7;
}

.SpanMenuItem {
  font-size: 16px;
  margin-left: 32px;
  padding: 4px 0px;
  color: #d91717;
}
